<template>
  <div id="eldropzone" >
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-100 md-small-size-100">
          <div class="upload-file">
            <input
            @click="reset_input()"
            style="width: 530px;"
            :disabled="sending"
              :id="'files_'+prop_id"
              type="file"
              accept="application/vnd.ms-Excel,
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              :multiple="false"
            >
            <md-progress-bar md-mode="indeterminate" v-if="sending" />
          </div>
      </div>
    </div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-small-size-50 md-size-25">
        <md-checkbox v-model="select_partida"
        :disabled="sending"
        >¿Generar Partida?</md-checkbox>
          <span v-if="select_partida" style="color: #ff5252;
            font-weight: 500;" for="services">SÍ</span>
          <span v-if="!select_partida" style="color: #ff5252;
              font-weight: 500;" for="services">NO</span>
      </div>
      <div class="md-layout-item md-small-size-50 md-size-25">
        <md-field >
          <label for="period">Año fiscal</label>
          <md-select v-model="fiscal_year_id" @md-selected="get_list_periods(true)"
          :disabled="sending">
          <md-option value="">----</md-option>
          <md-option :value="item.id"
            v-for="(item, key) in list_years"
            :key="key">{{item.year}}</md-option>
          </md-select>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-50 md-size-25">
        <md-field>
          <label for="period">Periodos</label>
          <md-select v-model="period_id" :disabled="sending">
          <md-option value="">----</md-option>
          <md-option :value="item.id"
            v-for="(item, key) in list_periods"
            :key="key">{{item.period}}</md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <md-button class="md-raised md-primary" :disabled="
        sending || (info.fecha_consulta && !info.with_erros)"
        @click="get_files()">Subir archivo</md-button>
        <br>
        <a href="#" disabled="disabled" class="md-accent"
        @click.prevent="reset_input()" title="Click para limpiar">Limpiar</a>
      </div>
      <div class="md-layout-item md-small-size-100">
        <md-button @click="get_files(true)"
        v-if="!info.with_erros && info.fecha_consulta && !info.with_error_partida"
          class="md-raised md-accent" >Guardar datos</md-button>
        <md-button v-if="info.fecha_consulta && info.with_erros && info.with_error_partida"
        class="md-raised md-accent" disabled="disabled"
        >Guardar datos </md-button>
        <md-button v-if="info.fecha_consulta && info.with_erros && !info.with_error_partida"
        class="md-raised md-accent" disabled="disabled"
        >Guardar datos </md-button>
        <md-button v-if="info.fecha_consulta && !info.with_erros && info.with_error_partida"
        class="md-raised md-accent" disabled="disabled"
        >Guardar datos </md-button>
        <md-button v-if="!info.fecha_consulta && !info.with_erros && !info.with_error_partida"
        class="md-raised md-accent" disabled="disabled"
        >Guardar datos </md-button>
      </div>
      <md-list class="md-triple-line list">
         <md-list-item>
           <md-icon>schedule</md-icon>
           <span class="md-list-item-text">{{info.fecha_consulta|format_date_time_long}}</span>
         </md-list-item>
      </md-list>
    </div>
    <br>
    <md-tabs>
      <md-tab title="VENTAS" md-label="VENTAS" id="tab-ventas">
        <md-card-content >
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-table md-card v-for="(item, index) in documentSaved
                .filter(item => item.sells)
                .filter(item => item.sells.sell)" :key="index+'_purchase_root'">
                <md-table-toolbar>
                  <h1 class="md-title">Documentos en
                  {{item.mes}} {{item.sells.year}}</h1>

                  <table border="1" width="50%">
                    <thead>
                      <th colspan="3">
                        Resumen
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <th>Total sin iva</th>
                        <th>Iva débito</th>
                      </tr>
                      <tr>
                        <td>Exportaciones</td>
                        <td> {{item.sells.sell.
                          filter( item => item.exports).reduce((total, item) => {
                          return total + parseFloat(item.exports);},0)|formatPrice}}</td>
                          <td>
                            0.00
                          </td>
                      </tr>
                      <tr>
                        <td>Ventas Excentas</td>
                        <td> {{item.sells.sell.
                          filter( item => item.exempt_goods).reduce((total, item) => {
                          return total + parseFloat(item.exempt_goods);},0)|formatPrice}}</td>
                          <td>
                            0.00
                          </td>
                      </tr>
                      <tr>
                        <td>Servicios Excentos</td>
                        <td> {{item.sells.sell.
                          filter( item => item.exempt_services).reduce((total, item) => {
                          return total + parseFloat(item.exempt_services);},0)|formatPrice}}</td>
                          <td>
                           0.00
                          </td>
                      </tr>
                      <tr>
                        <td>Venta de Bienes</td>
                        <td>
                          {{item.sells.sell
                            .filter( item => item.goods)
                            .filter( item => !item.fuel)
                            .reduce(
                            (total, item)=>
                            {return total + parseFloat(item.total_goods_sin_iva);},0)|formatPrice}}
                        </td>
                        <td>
                          {{item.sells.sell.
                            filter(item => item.goods)
                            .filter( item => !item.fuel)
                            .reduce((total, item) => {
                              return total + parseFloat(
                            item.iva_bienes);},0)|formatPrice}}
                        </td>
                      </tr>
                      <tr>
                        <td>Venta de Servicios</td>
                        <td>{{item.sells.sell.
                          filter(item => item.services)
                          .reduce((total, item) => {
                            return total + parseFloat(
                          item.total_services_sin_iva);},0)|formatPrice}}</td>
                        <td>
                          {{item.sells.sell.
                            filter(item => item.services)
                            .reduce((total, item) => {
                              return total + parseFloat(
                            item.iva_servicios);},0)|formatPrice}}
                        </td>
                      </tr>
                      <tr>
                        <td>Totales</td>
                        <td>{{item.sells.sell
                            .filter( item => item.base).reduce((total, item) => {
                            return total + parseFloat(
                          item.amount_sin_iva);},0)|formatPrice}}</td>
                        <td>
                          {{item.sells.sell
                            .filter( item => item.iva).reduce((total, item) => {
                            return total + parseFloat(item.iva);},0)|formatPrice}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </md-table-toolbar>
                <p>Registro encontrados: {{item.sells.total_registros_encontrados}}</p>
                <p>Registro guardados: {{info.total_registros_guardados_ventas}}</p>
                <md-table-row>
                  <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
                  <md-table-head>Comprador</md-table-head>
                  <md-table-head>Serie-Número</md-table-head>
                  <md-table-head>Fecha</md-table-head>
                  <md-table-head>Bienes</md-table-head>
                  <md-table-head>Servicios</md-table-head>
                  <md-table-head>Servicios excentos</md-table-head>
                  <md-table-head>Bienes excento</md-table-head>
                  <md-table-head>Exportaciones</md-table-head>
                  <md-table-head>IVA</md-table-head>
                </md-table-row>
                <md-table-row
                v-for="(sell, k) in item.sells.sell" :key="k+'_sell'">
                  <md-table-cell class="md-xsmall-hide md-small-hide" >{{k + 1}}
                    <md-icon class="md-accent" v-if="sell.has_error">error</md-icon>
                    <md-icon class="md-primary" v-if="!sell.has_error">
                      <span v-if="!sell.id">done</span>
                      <span v-if="sell.id">done_all</span>
                    </md-icon>
                  </md-table-cell>
                  <md-table-cell  md-sort-by="customer_name" >
                  {{ sell.customer_name }}</md-table-cell>
                  <md-table-cell  md-sort-by="first_name" >
                  {{ sell.dte_serie }} - {{ sell.dte_numero }}</md-table-cell>
                  <md-table-cell >
                  {{ sell.date_invoice|to_date }}</md-table-cell>
                  <md-table-cell  md-sort-by="first_name" >
                    <span v-if="sell.goods">{{ sell.goods|formatPrice }}</span>
                    <span v-if="!sell.goods">---</span>
                  </md-table-cell>
                  <md-table-cell  md-sort-by="first_name" >
                    <span v-if="sell.services">{{ sell.services|formatPrice }}</span>
                    <span v-if="!sell.services">---</span>
                  </md-table-cell>
                  <md-table-cell  md-sort-by="first_name" >
                    <span v-if="sell.exempt_services">{{ sell.exempt_services|formatPrice }}</span>
                    <span v-if="!sell.exempt_services">---</span>
                  </md-table-cell>
                  <md-table-cell  md-sort-by="first_name" >
                    <span v-if="sell.exempt_goods">{{ sell.exempt_goods|formatPrice }}</span>
                    <span v-if="!sell.exempt_goods">---</span>
                  </md-table-cell>
                  <md-table-cell  md-sort-by="first_name" >
                    <span v-if="sell.exports">{{ sell.exports|formatPrice }}</span>
                    <span v-if="!sell.exports">---</span>
                  </md-table-cell>
                  <md-table-cell  md-sort-by="first_name" >
                  {{ sell.iva|formatPrice }}</md-table-cell>
                </md-table-row>
              </md-table>
              <div v-if="loaded" class="md-subheading">
                <span v-if='documentSaved
                .filter(item => item.sells)
                .filter(item => item.sells.sell).length ===0'>Sin resultados</span>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-tab>
      <md-tab title="COMPRAS" md-label="COMPRAS" id="tab-compras">
        <md-card-content >
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-table md-card v-for="(item, index) in documentSaved
                .filter(item => item.purchases)
                .filter(item => item.purchases.purchase)" :key="index">
                <md-table-toolbar>
                  <h1 class="md-title">
                  {{item.mes}} {{item.purchases.year}}</h1>
                  <table border="1" width="50%">
                    <thead>
                      <th colspan="3">
                        Resumen
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <th>Total sin iva</th>
                        <th>Iva crédito</th>
                      </tr>
                      <tr>
                        <td>Combustibles</td>
                        <td> {{item.purchases.purchase.
                          filter( item => item.fuel).reduce((total, item) => {
                          return total + parseFloat(item.fuel_sin_iva);},0)|formatPrice}}</td>
                          <td>
                            {{item.purchases.purchase.
                              filter(item => item.fuel)
                              .filter( item => item.type_document != 'P')
                              .filter( item => item.type_document != 'Q')
                              .reduce((total, item) => {
                                return total + parseFloat(
                              item.iva_combustile);},0)|formatPrice}}
                          </td>
                      </tr>
                      <tr>
                        <td>Bienes</td>
                        <td>
                          {{item.purchases.purchase
                            .filter( item => item.total_goods_sin_iva)
                            .filter( item => item.type_document != 'P')
                            .filter( item => item.type_document != 'Q')
                            .filter( item => !item.fuel)
                            .reduce(
                            (total, item)=>
                            {return total + parseFloat(item.total_goods_sin_iva);},0)|formatPrice}}
                        </td>
                        <td>
                          {{item.purchases.purchase.
                            filter(item => item.iva_bienes)
                            .filter( item => item.type_document != 'P')
                            .filter( item => item.type_document != 'Q')
                            .reduce((total, item) => {
                              return total + parseFloat(
                            item.iva_bienes);},0)|formatPrice}}
                        </td>
                      </tr>
                      <tr>
                        <td>Servicios</td>
                        <td>{{item.purchases.purchase.
                          filter(item => item.total_services_sin_iva)
                          .filter( item => item.type_document != 'P')
                          .filter( item => item.type_document != 'Q')
                          .reduce((total, item) => {
                            return total + parseFloat(
                          item.total_services_sin_iva);},0)|formatPrice}}</td>
                        <td>
                          {{item.purchases.purchase.
                            filter(item => item.iva_servicios)
                            .filter( item => item.type_document != 'P')
                            .filter( item => item.type_document != 'Q')
                            .reduce((total, item) => {
                              return total + parseFloat(
                            item.iva_servicios);},0)|formatPrice}}
                        </td>
                      </tr>
                      <tr>
                        <td>IDP</td>
                        <td>{{item.purchases.purchase.
                            filter( item => item.idp).reduce((total, item) => {
                            return total + parseFloat(item.idp);},0)|formatPrice}}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Bienes y servicios FPC</td>
                        <td>
                          {{item.purchases.purchase
                           .filter( item => item.type_document==='P'||item.type_document==='Q')
                           .reduce((total, item) => {
                            return total + parseFloat(item.amount);},0)|formatPrice}}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Totales</td>
                        <td>{{item.purchases.purchase
                            .filter( item => item.amount_sin_iva).reduce((total, item) => {
                            return total + parseFloat(
                          item.amount_sin_iva);},0)|formatPrice}}</td>
                        <td>
                          {{item.purchases.purchase
                            .filter( item => item.iva).reduce((total, item) => {
                            return total + parseFloat(item.iva);},0)|formatPrice}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </md-table-toolbar>
                <p>Registro encontrados:
                {{item.purchases.total_registros_encontrados}}</p>
                <p>Registro guardados:
                {{info.total_registros_guardados_compras}}</p>
                <md-table-row>
                  <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
                  <md-table-head>Proveedor</md-table-head>
                  <md-table-head>Serie-Número</md-table-head>
                  <md-table-head>Fecha documento</md-table-head>
                  <md-table-head>Bienes</md-table-head>
                  <md-table-head>Servicios</md-table-head>
                  <md-table-head>Combustible</md-table-head>
                  <md-table-head>IDP</md-table-head>
                  <md-table-head>IVA</md-table-head>
                </md-table-row>
                <md-table-row
                v-for="(purchase, k) in item.purchases.purchase" :key="k">
                  <md-table-cell class="md-xsmall-hide md-small-hide" >{{k + 1}}
                    <md-icon>
                      <a v-if="purchase.partida_con_error || purchase.has_error" class="md-accent"
                      href="#" @click.prevent="open_modal(purchase)">error</a>
                    </md-icon>
                    <md-icon>
                      <a v-if="!purchase.partida_con_error && !purchase.has_error"
                      class="md-primary"
                      @click.prevent="open_modal(purchase)" href="#">
                      <span v-if="!purchase.id">done</span>
                      <span v-if="purchase.id">done_all</span>
                      </a>
                    </md-icon>
                  </md-table-cell>
                  <md-table-cell  >
                  {{ purchase.provider_name }}
                  <md-chip v-if="purchase.type_document == 'Q' || purchase.type_document == 'P'"
                  class="md-accent" md-clickable>PEQ.</md-chip>
                  <md-chip v-if="purchase.type_document == 'D' || purchase.type_document == 'F'"
                  class="md-accent" md-clickable>DTE</md-chip>
                  <md-chip v-if="purchase.type_document == 'R' || purchase.type_document == 'G'"
                  class="md-accent" md-clickable>NOTA CRÉDITO</md-chip>
                  <md-chip v-if="purchase.type_document == 'B' || purchase.type_document == 'K'"
                  class="md-accent" md-clickable>NOTA DÉDITO</md-chip>
                  <md-chip v-if="purchase.type_document == 'Y' || purchase.type_document == 'A'"
                  class="md-accent" md-clickable>CAMBIARIA</md-chip>
                </md-table-cell>
                  <md-table-cell  >
                  {{ purchase.dte_serie }}-{{ purchase.number_invoice_purcharse }}</md-table-cell>
                  <md-table-cell >
                  {{ purchase.date_purchase|to_date }}</md-table-cell>
                  <md-table-cell >
                  {{ purchase.goods|formatPrice }}</md-table-cell>
                  <md-table-cell >
                  {{ purchase.services|formatPrice }}
                </md-table-cell>
                  <md-table-cell >
                    <span v-if="purchase.fuel">{{ purchase.fuel|formatPrice }}</span>
                    <span v-if="!purchase.fuel">---</span>
                  </md-table-cell>
                  <md-table-cell >
                    <span v-if="purchase.idp">{{ purchase.idp|formatPrice }}</span>
                    <span v-if="!purchase.idp">---</span>
                  </md-table-cell>
                  <md-table-cell >
                  {{ purchase.iva|formatPrice }}</md-table-cell>
              </md-table-row>
              </md-table>
              <div v-if="loaded" class="md-subheading">
                <span v-if='documentSaved
                .filter(item => item.purchases)
                .filter(item => item.purchases.purchase).length ===0'>Sin resultados</span>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-tab>
      <md-tab title="ERRORES EN VENTAS" md-label="ERRORES EN VENTAS" id="tab-errores-ventas">
        <md-card-content >
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-table md-card v-for="(item, index) in documentSaved
                .filter(item => item.sells)
                .filter(item => item.sells.errors)" :key="index+'_sell_error_root'">
                <md-table-toolbar>
                  <h1 class="md-title">Errores en documentos en
                  {{item.mes}} {{item.sells.year}}</h1>
                </md-table-toolbar>
                <md-table-row>
                  <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
                  <md-table-head>Error</md-table-head>
                </md-table-row>
                <md-table-row
                v-for="(e, k) in item.sells.errors" :key="k+'_sell_error'"
                :class="{ 'error-text': e.already_exist}">
                  <md-table-cell class="md-xsmall-hide md-small-hide" >{{k + 1}}</md-table-cell>
                  <md-table-cell  md-sort-by="first_name" >
                  {{ e.error }}</md-table-cell>
              </md-table-row>
              </md-table>
            </div>
          </div>
        </md-card-content>
      </md-tab>
      <md-tab title="ERRORES EN COMPRAS" md-label="ERRORES EN COMPRAS" id="tab-errores-compras">
        <md-card-content >
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-table md-card v-for="(item, index) in documentSaved
                .filter(item => item.purchases)
                .filter(item => item.purchases.errors)" :key="index+'_purchase_error_root'">
                <md-table-toolbar>
                  <h1 class="md-title">Errores en documentos
                  {{item.mes}} {{item.purchases.year}}</h1>
                </md-table-toolbar>
                <md-table-row>
                  <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
                  <md-table-head>Error</md-table-head>
                </md-table-row>
                <md-table-row
                v-for="(e, k) in item.purchases.errors" :key="k+'_purchase_error'"
                :class="{ 'error-text': e.already_exist}">
                  <md-table-cell class="md-xsmall-hide md-small-hide" >{{k + 1}}</md-table-cell>
                  <md-table-cell  md-sort-by="first_name" >
                  {{ e.error }}</md-table-cell>
              </md-table-row>
              </md-table>
            </div>
          </div>
        </md-card-content>
      </md-tab>
    </md-tabs>
    <md-card-content v-if="errors.length > 0">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          <md-table md-card >
            <md-table-toolbar>
              <h1 class="md-title">Errores </h1>
            </md-table-toolbar>
            <md-table-row>
              <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
              <md-table-head>Error</md-table-head>
            </md-table-row>
            <md-table-row
            v-for="(e, k) in errors" :key="k+'_general'">
              <md-table-cell class="md-xsmall-hide md-small-hide" >{{k + 1}}</md-table-cell>
              <md-table-cell >
              {{ e.error }}</md-table-cell>
          </md-table-row>
          </md-table>
        </div>
      </div>
    </md-card-content>
    <md-snackbar
      :md-active.sync="fileSeleted">
      El documento ha sido seleccionado!</md-snackbar>
    <md-snackbar
      :md-active.sync="loaded">
      Se ha finalizado la carga!</md-snackbar>

    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Detalle partidas</md-dialog-title>
      <md-card-content >
        <md-table>
          <md-table-row>
            <md-table-head>Seire</md-table-head>
            <md-table-cell>{{partida_list.dte_serie}}</md-table-cell>
            <md-table-head>Número</md-table-head>
            <md-table-cell>{{partida_list.number_invoice_purcharse}}</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-head>Proveedor</md-table-head>
            <md-table-cell>{{partida_list.provider_name}}</md-table-cell>
            <md-table-head>IVA Crédito</md-table-head>
            <md-table-cell>{{partida_list.iva|formatPrice}}</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-head>Mensaje eror</md-table-head>
            <md-table-cell>{{partida_list.partida_con_error_ms}}</md-table-cell>
            <md-table-head>Total sin iva</md-table-head>
            <md-table-cell>{{partida_list.base|formatPrice}}</md-table-cell>
          </md-table-row>
        </md-table>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-table>
              <md-table-row>
                <md-table-head>Nombre</md-table-head>
                <md-table-head>Monto</md-table-head>
                <md-table-head>Encontrado</md-table-head>
              </md-table-row>
              <md-table-row v-for="(item, i) in partida_list.accounts" :key="i+'_account'">
                <md-table-cell>
                  <md-button class="md-primary">{{item.account_name}}</md-button>
                </md-table-cell>
                <md-table-cell>{{item.amount|formatPrice}}</md-table-cell>
                <md-table-cell>
                  <md-icon class="md-accent" v-if="!item.account">warning</md-icon>
                  <md-icon class="md-primary" v-if="item.account">check</md-icon>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">Cerrar</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'UploadMulty',
  created() {
    this.add_event_drop_ele();
    const yearSelected = this.get_data_storage_raw('id_fiscal_year');
    if (yearSelected) {
      this.fiscal_year_id = yearSelected;
    }
    this.get_list_periods();
    this.get_list_years();
  },
  data() {
    return {
      base: process.env.VUE_APP_BASE_URL,
      instance: {
        data: [],
      },
      errors: [],
      documentSaved: [],
      sending: false,
      showDialog: false,
      select_partida: true,
      iva_sobre_total: false,
      period_id: null,
      fiscal_year_id: null,
      fileSeleted: false,
      loaded: false,
      sending_request: false,
      persistir_datos: false,
      info: {},
      partida_list: [],
      list_periods: [],
      list_years: [],
      url_periods: 'periods/',
      url_fiscal_year: 'fiscal_year',
    };
  },
  props: {
    apply_class: {
      type: String,
      default: null,
    },
    periodo: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    prop_id: {
      type: String,
      default: 'file_1',
    },
  },
  methods: {
    open_modal(data) {
      this.partida_list = data;
      this.showDialog = true;
    },
    reset_input() {
      if (!this.sending) {
        const input = document.querySelector(`#files_${this.prop_id}`);
        input.value = '';
      }
      this.persistir_datos = false;
      this.info = {};
    },
    validar_archivos(files) {
      const tipos = ['xls', 'xlsx'];
      /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
      for (let file = 0; file < files.length; file++) {
        if (files[file].size === 0) {
          this.$swal.fire('Error!', `El archivo  ${files[file].name}  esta vacio`, 'error');
          return false;
        }
        if (files[file].size > 20000000) {
          this.$swal.fire('Error!', `El archivo ${files[file].name} supera el límite permitido`, 'error');
          return false;
        }
        if (tipos.includes(files[file].type)) {
          this.$swal.fire('Error!', `El archivo ${files[file].name} tiene un formato no permitido.`, 'error');
          return false;
        }
        const imageType = /video.*/;
        if (files[file].type.match(imageType)) {
          this.$swal.fire('Error!', `El archivo ${files[file].name} tiene un formato no permitido.`, 'error');
          return false;
        }
      }
      return true;
    },
    async add_event_drop_ele() {
      this.$nextTick(() => {
        const dropZoneDiv = document.querySelector('#eldropzone');
        const inputfile = document.querySelector(`#files_${this.prop_id}`);
        const self = this;
        inputfile.addEventListener('change', () => {
          self.fileSeleted = true;
        }, false);

        dropZoneDiv.ondragover = (event) => {
          dropZoneDiv.classList.add('dropzone-hover');
          event.stopPropagation();
          event.preventDefault();
          /* eslint no-param-reassign: [2, { "props": false }] */
          event.dataTransfer.dropEffect = 'copy';
        };
        dropZoneDiv.ondragleave = () => {
          dropZoneDiv.classList.remove('dropzone-hover');
        };
        // Handle file drop:
        dropZoneDiv.ondrop = async (ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          dropZoneDiv.classList.remove('dropzone-hover');
          const { files } = ev.dataTransfer;
          this.fileSeleted = true;
          this.persistir_datos = false;
          this.SeletedFile2(files);
        };
      });
      // Configure dropZoneDiv
    },
    emit_data2() {
      this.$emit('get_id_document', this.instance);
    },
    async postFileRequest(payload) {
      const data = await this.$http.post(
        this.url,
        payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    },
    async putFileRequest(payload) {
      const data = await this.$http.put(
        `pictures/${this.avatar_id}/`,
        payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    },
    get_files(persistir = false) {
      const docs = document.querySelector(`#files_${this.prop_id}`);
      if (persistir) {
        this.persistir_datos = true;
      }
      this.SeletedFile2(docs.files);
    },
    SeletedFile2(docs) {
      const result = this.validar_archivos(docs);
      if (!result) return;
      if (this.sending) return;
      if (docs.length === 0) {
        this.$swal.fire('Error!', 'Al menos un archivo es requerido.', 'error');
        return;
      }
      const formData = new FormData();
      this.sending = true;
      this.loaded = false;
      this.documentSaved = [];
      this.errors = [];
      for (let file = 0; file < docs.length; file += 1) {
        formData.append('file', docs[file]);
        if (this.period_id) {
          formData.append('period', this.period_id);
          formData.append('fiscal_year', this.fiscal_year_id);
          formData.append('only_period', this.period_id);
        }
        formData.append('company', this.$store.state.company_obj.id);
        if (this.select_partida) {
          formData.append('crear_partida', this.instance.select_partida);
        }
        if (this.iva_sobre_total) {
          formData.append('iva_sobre_total', this.instance.iva_sobre_total);
        }
        if (this.persistir_datos) {
          formData.append('persistir_datos', this.persistir_datos);
        }
        this.postFileRequest(formData).then((data) => {
          if (file + 1 === docs.length) {
            this.documentSaved = data.data.data;
            this.info = data.data;
            this.sending = false;
            this.loaded = true;
            if (this.info.con_persistencia) {
              formData.delete('persistir_datos');
              this.$swal.fire('Guardado!', 'Los datos han sido guardados.', 'success');
            }
          }
        }).catch((error) => {
          if (this.persistir_datos) {
            formData.delete('persistir_datos');
          }
          this.sending = false;
          this.loaded = true;
          if (error.response) {
            if (typeof (error.response.data) === 'object' && error.response.status !== 406) {
              Object.entries(error.response.data).forEach(([key, value]) => { this.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
              return false;
            }
            this.errors = error.response.data;
          }
          // this.error = error
          return false;
        });
      }
    },
    open_finder2() {
      document.querySelector(`#files_${this.prop_id}`).click();
    },
    update_logo(payload) {
      const self = this;
      this.putFileRequest(payload).then((data) => {
        self.instance.id = data.data.id;
        self.emit_data2();
      }).catch((error) => {
        console.log(error);
      });
    },
    create_logo2(payload) {
      const self = this;
      this.postFileRequest(payload).then((data) => {
        self.instance = data.data;
        self.emit_data2();
      }).catch((error) => {
        console.log(error);
      });
    },
    get_list_periods() {
      const payload = {};
      payload.fiscal_year = this.fiscal_year_id;
      const self = this;
      this.period_id = null;
      if (this.sending_request) return false;
      this.sending_request = true;
      this.sendRequest_with_url(this.url_periods, payload).then((data) => {
        this.list_periods = data.data;
        this.sending_request = false;
      }).catch((error) => {
        self.sending_request = false;
        self.handlerError(error);
      });
      return null;
    },
    get_list_years() {
      const payload = {};
      const self = this;
      this.sendRequest_with_url(this.url_fiscal_year, payload).then((data) => {
        self.sending_request = false;
        self.list_years = data.data;
      }).catch((error) => {
        self.sending_request = false;
        self.handlerError(error);
      });
      return null;
    },
    async sendRequest_with_url(url, payload) {
      const result = await this.$http.get(url, { params: payload });
      return result;
    },
    get_data_storage_raw(name) {
      return localStorage.getItem(name);
    },
  },
  filters: {
    status_to_text(value) {
      if (value) return 'Sí';
      return 'No';
    },
    format_amount(value) {
      if (!value) return '';
      return Number.isNaN(value) ? 0 : parseFloat(value).toFixed(2);
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    to_date(value) {
      if (!value) return '-----';
      return moment(value, 'YYYY-MM-DD', 'es').format('DD/MM/YYYY');
    },
    format_date_time(value) {
      if (!value) return '-----';
      return moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
    },
    format_date_time_long(value) {
      if (!value) return '-----';
      return moment(value, 'YYYY-MM-DD HH:mm:ss').locale('es').format('LLL');
    },
  },
};
</script>
<style scope>
  .null {
    text-decoration: line-through !important;
  }
  .upload-file {
    border-style: dotted;
    border-style-color: red !important;
  }
  .dropzone {
    border: 2px dotted #bbb;
    border-radius: 10px;
    padding: 5px;
    color: #bbb;
    text-align: center;
    height: auto;
  }
  .dropzone-hover {
    border: 4px dotted #bbb !important;
    color: #bbb0;
  }
  @media screen(max-width: 900px) {
    .dropzone {
      padding: 10px !important;
    }
  }
  @media screen(max-width: 600px) {
    .dropzone {
      padding: 10px !important;
      font-size: 10px;
    }
  }
  .error-text {
    color: #958c8c;
  }
  .md-card-content{
    overflow-y: auto;
  }
</style>
